import React from 'react'
import Seo from '../components/Seo/Seo'
import { graphql, Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const slugify = (string) => {
  return string
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/, '')
    .replace(/\s/g, '-')
}

const Categories = ({
  location,
  data: {
    allMarkdownRemark: { group },
  },
}) => (
  <>
    <Seo
      title="الفئات"
      description="قائمة بجميع التدوينات مرتبة حسب الفئة."
      path={location.pathname}
    />

    <div className="container py-16 px-3 mx-auto max-w-3xl">
      <h1>الفئات</h1>

      {/**
      <ul className="list-reset pt-4 pb-8 border-b overflow-x-scroll whitespace-nowrap shadow-md md:shadow-none px-3 -mx-3">
        {group.map((category) => (
          <li
            key={category.fieldValue}
            className="inline btn btn--lightest btn--sm mr-2"
          >
            <a href={`#${slugify(category.fieldValue)}`}>
              {category.fieldValue}
            </a>
          </li>
        ))}
      </ul>
       */}
      {/** new category ordering */}
      <ul className="list-reset pt-4 pb-8 border-b overflow-x-scroll whitespace-nowrap shadow-md md:shadow-none px-3 -mx-3 flex">
        {group.map((category) => (
          <div key={category.fieldValue} className="">
            <li className="inline btn btn--lightest btn--sm mr-2">
              <a href={`#${slugify(category.fieldValue)}`}>
                {category.fieldValue}
              </a>
            </li>
          </div>
        ))}
      </ul>
      {/** new category ordering */}

      <ul className="list-reset mb-8">
        {group.map((category) => (
          <React.Fragment key={category.fieldValue}>
            <div id={slugify(category.fieldValue)} className="mt-12 mb-2">
              <h2 className="inline btn btn--lightest btn--sm">
                {category.fieldValue}
              </h2>
            </div>

            {category.edges.map(({ node: post }) => (
              <li
                className="mb-6"
                key={post.id}
                itemScope=""
                itemType="http://schema.org/BlogPosting"
              >
                <h2 className="font-semibold m-0 leading-tight mb-2">
                  <Link
                    to={post.fields.slug}
                    className="text-black"
                    hrefLang={post.fields.lang}
                  >
                    <span itemProp="name">{post.frontmatter.title}</span>
                  </Link>
                </h2>

                <div className="text-gray-700 text-lg ">
                  <FontAwesomeIcon
                    icon={['far', 'calendar-alt']}
                    className="mr-1 text-indigo-200"
                  />{' '}
                  <span
                    itemProp="datePublished"
                    className="font-light"
                    content={post.fields.datePublished}
                  >
                    {post.fields.date}
                  </span>
                </div>
              </li>
            ))}
          </React.Fragment>
        ))}
      </ul>
    </div>
  </>
)

export default Categories
export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "post" } } }
      sort: { fields: [fields___date], order: DESC }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        edges {
          node {
            id
            fields {
              slug
              lang
              datePublished: date(formatString: "DD-MM-YYYY")
              date: date(formatString: "DD-MM-YYYY", locale: "en")
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  }
`
